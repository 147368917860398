<template>
  <!-- 策略订单页面 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>策略订单报表</h4>
    </div>
    <!-- 搜索区域 -->
    <div class="form">
      <!-- 手机号查询 -->
      <div>
        <span>按时间查询</span>
        <div style="display: flex">
          <el-date-picker v-model="form.time1" type="date" placeholder="开始日期"> </el-date-picker>
          <el-date-picker v-model="form.time2" type="date" placeholder="结束日期"> </el-date-picker>
        </div>
        策略订单金额汇总:0
      </div>
      <div class="theQuery-Tow">
        <p>按支付方式查询</p>
        <el-select v-model="approve" placeholder="请选择">
          <el-option v-for="item in attestation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="theQuery-Tow">
        <p>按状态查询</p>
        <el-select v-model="approve" placeholder="请选择">
          <el-option v-for="item in attestation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="theQuery-Tow">
        <p>按状态查询</p>
        <el-select v-model="approve" placeholder="请选择">
          <el-option v-for="item in attestation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="theQuery-Tow">
        <p>按数字通证查询</p>
        <el-select v-model="approve" placeholder="请选择">
          <el-option v-for="item in attestation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div>
        <span>按名称查询</span>
        <el-input placeholder="请输入商品名称" v-model="form.phone" clearable style="width: 200px; display: block"> </el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="doFilter">查询</el-button>
      <el-button type="primary" icon="el-icon-download" style="height: 40px; margin-top: 40px; margin: left 30px">导出</el-button>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="dataUser" border style="width: 100%">
        <el-table-column prop="id" label="订单号" align="center"> </el-table-column>
        <el-table-column prop="tel" label="名称" align="center"> </el-table-column>
        <el-table-column prop="tel" label="充值金额" width="" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="到账金额" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="支付方式" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="支付信息" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="对应数字通证" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="下单时间" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="支付时间" width="" align="center"> </el-table-column>
        <el-table-column prop="inviteNum" label="状态" width="" align="center"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" :plain="true" @click="onStatus">锁定</el-button>
            <el-button size="mini" type="success" :plain="true" @click="onStatusPwd">密码解锁</el-button>
            <el-button size="mini" type="primary" @click="handleDelete(scope.$index, scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页选项 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[1, 2, 3, 4, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        time1: '',
        time2: '',
        phone: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  div {
    margin-right: 20px;
    span {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.text {
  h4 {
    margin: 10px;
    color: #409eff;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 150px;
    height: 60px;
  }
}
</style>